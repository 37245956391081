import React, { ReactNode, Suspense } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

const GuidePage = React.lazy(() => import('../pages/guide'));
const GamePage = React.lazy(() => import('../pages/game'));
const ContactUsPage = React.lazy(() => import('../pages/contact'));
const TermsPage = React.lazy(() => import('../pages/terms'));
const PolicyPage = React.lazy(() => import('../pages/policy'));

const RedirectHome = <Navigate to='/' replace />;

const routes = [
    {
        name:'game',
        path: '/',
        element: <GamePage/>,
    },
    {
        name:'guide',
        path: '/guide',
        element: <GuidePage/>,
    },
    {
        name:'contact-us',
        path: '/contact-us',
        element: <ContactUsPage/>,
    },
    {
        name:'termsofuse',
        path: '/tos',
        element: <TermsPage/>,
    },
    {
        name:'privacypolicy',
        path: '/privacy',
        element: <PolicyPage/>,
    },
    {
        name:'redirect',
        path: '*',
        element: RedirectHome,
    }
];

const renderRoutes = (routes: any) => {
    return (
        <>
            {routes.map((route: any) => {
                if (route.children) {
                    return <Route key={route.name} path={route.path} element={route.element}>{renderRoutes(route.children)}</Route>
                } else {
                    return <Route key={route.name} path={route.path} element={route.element} />
                }
            })}
        </>)
};

const router = () => {
    return (
        <Routes>
            {renderRoutes(routes)}
        </Routes>
    );
};

export default router;