import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { initServiceWorker } from './utils/serviceworker';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

initServiceWorker();

window.addEventListener('beforeinstallprompt', e => {
  e.preventDefault();
  window.promptEvent = e;
});

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
