let refreshing = false;

export const initServiceWorker = () => {
    if ('serviceWorker' in navigator) {
        const { serviceWorker } = navigator;
        serviceWorker.getRegistrations().then(regs => {
            for (let reg of regs) {
                reg.unregister();
            }
            serviceWorker.register('/sw.js').then(() => {
                console.log('Service Worker success');
            }, (e)=> {
                console.log('error--->', e);
            })
            serviceWorker.addEventListener('controllerchange', e => {
                if (refreshing) return;
                refreshing = true;
                window.location.reload();
            });
        });
    }
}